<template>
  <f-card
    header="Alpha Assistance"
    header-center
  >
    <f-form @submit="onSubmit">
      <f-input
        v-model="username"
        :label="$t('labels.username')"
        rules="required"
      />
      <f-input
        v-model="password"
        autocomplete="on"
        :label="$t('labels.password')"
        rules="required"
        type="password"
      />
      <div class="form-footer mt-5">
        <f-button
          :label="$t('labels.login')"
          type="submit"
          variant="primary btn-block"
        />
      </div>
    </f-form>
  </f-card>
</template>

<script>
export default {
  data () {
    return {
      username: '',
      password: '',
      storeFunctionName: this.$flex.config.api.rootUrl ? 'login' : 'fakeLogin',
      isProduction: false
    }
  },
  async mounted () {
    this.isProduction = process.env.NODE_ENV === 'production'

    if (this.isProduction) {
      await this.$recaptchaLoaded()
      this.$recaptchaInstance.showBadge()
    }
  },
  methods: {
    async onSubmit () {
      let recaptchaToken

      if (this.isProduction) {
        recaptchaToken = await this.$recaptcha('login')
      }

      await this.login(this.username, this.password, recaptchaToken)
    },
    async login (username, password, recaptchaToken) {
      await this.$store.dispatch(this.storeFunctionName, {
        username,
        password,
        recaptchaToken
      })
      this.$router.push({ name: 'files' })

      if (this.isProduction) {
        this.$recaptchaInstance.hideBadge()
      }
    }
  }
}
</script>
